import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import OrderForm from "./screens/jucm/order-form"
import Videos from "./screens/jucm/videos"
import Details from "./screens/jucm/details"

const FORM_ID = process.env.GATSBY_FORM_JUCM_LP

const SexualHealthTest = () => {
  return (
    <React.Fragment>
      <OrderForm
        wordpressFormID={FORM_ID}
        thankYouPage="jucm-thank-you"
      />
      <Videos />
      <Details />
    </React.Fragment>
  )
}
export default SexualHealthTest

export const Head = () => (
  <Seo
	  title="The Only Instrument-Free, Rapid PCR Test"
	  description="Bring the power of the lab right to the point of need. Our instrument-free rapid, single-use PCR test may be used at any site with a CLIA certificate of waiver."
	  image="/meta/visby-medical.jpg"
/>
)
